@import "compiled/svg";
@import "global/fonts";

$color_1: #fff;
$color_2: #393939;
$color_3: inherit;
$color_4: #6e6e6e;
$color_5: transparent;
$color_6: #ebebeb;
$color_7: #d71921;
$color_8: silver;
$color_9: #000;
$color_10: #bd081c;
$color_11: #3b3b3b;
$font-family_1: nunito_sansbold,arial,sans-serif;
$font-family_2: sans-serif;
$font-family_3: nunito_sansregular,arial,sans-serif;
$font-family_4: nunito_sansextrabold,arial,sans-serif;
$font-family_5: nunito,arial,sans-serif;
$font-family_6: nunito sans,arial,sans-serif;
$font-family_7: mulish,sans-serif;
$background-color_1: #fff;
$background-color_2: silver;
$background-color_3: #d71921;
$background-color_4: #ebebeb;
$background-color_5: #6e6e6e;
$background-color_6: transparent;
$background-color_7: #f3f3f3;
$border-color_1: #000;

.badge-new {
    box-sizing: border-box;
    border-radius: 50%;
    text-align: center;
    font-family: $font-family_1;
    text-transform: uppercase;
    color: $color_1;
    border: 3px solid #fff;
    width: 50px;
    height: 50px;
    line-height: 44px;
    font-size: 15px;
    font-size: 0.9375rem;
}

.badge-sale {
    font-size: 15px;
    font-size: 0.9375rem;
    box-sizing: border-box;
    border-radius: 50%;
    text-align: center;
    font-family: $font-family_1;
    text-transform: uppercase;
    color: $color_1;
    border: 3px solid #fff;
    width: 50px;
    height: 50px;
    line-height: 44px;
    background: #d71921;
}

body {
    font-size: 15px;
    font-size: 0.9375rem;
    margin: 0;
    overflow-x: hidden;
    background: 0 0 #fff;
    font-family: $font-family_3;
    font-weight: 400;
    letter-spacing: 0.1px;
    color: $color_2;
    -moz-osx-font-smoothing: grayscale;
}

.clearfix {
    &::after {
        content: "";
        display: table;
        clear: both;
    }
}

.slick-track {
    &::after {
        content: "";
        display: table;
        clear: both;
    }

    position: relative;
    left: 0;
    display: block;
    top: 0;

    &::before {
        content: "";
        display: table;
    }
}

.visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.menu {
    float: left;
    margin: 0;
    padding: 0;

    li {
        float: left;
        list-style: none !important;
    }
}

.lone-link {
    overflow: hidden;
    color: $color_2;
    text-decoration: underline;
}

.icon.svg-account-dims {
    width: 20px;
    height: 20px;
}

.icon.svg-arrow-dims {
    width: 20px;
    height: 20px;
}

.icon.svg-back-to-top-icon-dims {
    width: 36px;
    height: 36px;
}

.icon.svg-cart-dims {
    width: 20px;
    height: 20px;
}

.icon.svg-email-icon-dims {
    width: 20px;
    height: 20px;
}

.icon.svg-facebook-dims {
    width: 20px;
    height: 20px;
}

.icon.svg-pinterest-dims {
    width: 20px;
    height: 20px;
}

.icon.svg-search-dims {
    width: 20px;
    height: 20px;
}

.icon {
    display: inline-block;
    vertical-align: middle;
    fill: #393939;
}

.icon.header-icons {
    fill: #393939;

    &:hover {
        fill: #393939;
    }
}

.icon.hoverable {
    &:hover {
        fill: #393939;
    }
}

.icon.close-icon {
    fill: #6e6e6e;
}

.icon.collapse {
    fill: #6e6e6e;
}

.icon.email-icon {
    fill: #6e6e6e;
}

.icon.expand {
    fill: #6e6e6e;
}

.icon.arrow {
    fill: #393939;
}

.icon.arrow-down {
    fill: #393939;
}

.icon.arrow-up {
    fill: #393939;
}

.icon.arrow.right {
    transform: rotate(180deg);
}

.icon.arrow.down {
    transform: rotate(-90deg);
}

html {
    font-family: $font-family_2;
    text-size-adjust: 100%;

    input[type="button"] {
        appearance: button;
        cursor: pointer;
    }
}

.slick-slide {
    img {
        display: block;
    }

    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}

article {
    display: block;
}

aside {
    display: block;
}

details {
    display: block;
}

figcaption {
    display: block;
}

figure {
    display: block;
}

footer {
    display: block;
    background-color: $background-color_1;
    clear: both;
    padding-top: 5px;
}

header {
    display: block;
}

hgroup {
    display: block;
}

main {
    display: block;
}

nav {
    display: block;
}

section {
    display: block;
}

summary {
    display: block;
}

audio {
    display: inline-block;
    vertical-align: baseline;
}

canvas {
    display: inline-block;
    vertical-align: baseline;
}

progress {
    display: inline-block;
    vertical-align: baseline;
}

video {
    display: inline-block;
    vertical-align: baseline;
}

a {
    background: 0 0;
    color: $color_3;
    text-decoration: inherit;
}

b {
    font-weight: 700;
}

strong {
    font-weight: 700;
}

sub {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
    top: -0.5em;
}

img {
    border: 0;
    max-width: 100vw;
}

svg {
    &:not(:root) {
        overflow: hidden;
    }

    use {
        pointer-events: none;
    }
}

hr {
    box-sizing: content-box;
    border: 0;
    height: 1px;
    background-color: $background-color_2;
}

button {
    font: inherit;
    margin: 0;
    overflow: visible;
    appearance: button;
    line-height: 1em;
    padding: 0.6563rem 8%;
    min-height: 2.5rem;
    font-family: $font-family_4;
    font-size: 16px;
    font-size: 1rem;
    display: inline-block;
    transition: all 0.3s ease;
    box-sizing: border-box;
    border-radius: 4px;
    letter-spacing: 0.2px;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    cursor: pointer;
    border: 2px solid #d71921;
    background-color: $background-color_3;
    color: $color_1;
}

input {
    font: inherit;
    margin: 0;
    color: $color_3;
    line-height: normal;

    &::-webkit-file-upload-button {
        border-radius: 0;
    }
}

optgroup {
    color: $color_3;
    font: inherit;
    margin: 0;
}

select {
    font: inherit;
    margin: 0;
    color: $color_3;
    text-transform: none;
}

textarea {
    font: inherit;
    margin: 0;
    font-size: 15px;
    font-size: 0.9375rem;
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    padding: 0 14px;
    border: 1px solid silver;
    border-radius: 3px;
    text-indent: 0;
    background: #fff;
    color: $color_2;

    &::input-placeholder {
        color: $color_4;
    }
}

input[type="date"] {
    &::input-placeholder {
        color: $color_4;
    }

    font-size: 15px;
    font-size: 0.9375rem;
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    padding: 0 14px;
    border: 1px solid silver;
    border-radius: 3px;
    text-indent: 0;
    background: #fff;
    color: $color_2;
}

input[type="email"] {
    &::input-placeholder {
        color: $color_4;
    }

    font-size: 15px;
    font-size: 0.9375rem;
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    padding: 0 14px;
    border: 1px solid silver;
    border-radius: 3px;
    text-indent: 0;
    background: #fff;
    color: $color_2;
}

input[type="number"] {
    &::input-placeholder {
        color: $color_4;
    }

    font-size: 15px;
    font-size: 0.9375rem;
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    padding: 0 14px;
    border: 1px solid silver;
    border-radius: 3px;
    text-indent: 0;
    background: #fff;
    color: $color_2;
}

input[type="password"] {
    &::input-placeholder {
        color: $color_4;
    }

    font-size: 15px;
    font-size: 0.9375rem;
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    padding: 0 14px;
    border: 1px solid silver;
    border-radius: 3px;
    text-indent: 0;
    background: #fff;
    color: $color_2;
}

input[type="tel"] {
    &::input-placeholder {
        color: $color_4;
    }

    font-size: 15px;
    font-size: 0.9375rem;
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    padding: 0 14px;
    border: 1px solid silver;
    border-radius: 3px;
    text-indent: 0;
    background: #fff;
    color: $color_2;
}

input[type="text"] {
    &::input-placeholder {
        color: $color_4;
    }

    font-size: 15px;
    font-size: 0.9375rem;
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    padding: 0 14px;
    border: 1px solid silver;
    border-radius: 3px;
    text-indent: 0;
    background: #fff;
    color: $color_2;
}

input[type="reset"] {
    appearance: button;
    cursor: pointer;
    border-radius: 0;
}

input[type="submit"] {
    appearance: button;
    cursor: pointer;
    border-radius: 0;
}

.slick-dots {
    li {
        display: inline-block;
        cursor: pointer;
        position: relative;
        height: 1.375rem;
        width: 1.375rem;
        margin: 0 0.3125rem;
        padding: 0;

        button {
            border: 0;
            background: 0 0;
            display: block;
            outline: 0;
            line-height: 0;
            font-size: 0;
            color: $color_5;
            cursor: pointer;
            height: 0.75rem;
            width: 0.75rem;
            padding: 0.3125rem;

            &::before {
                content: "";
                position: absolute;
                top: 0.3125rem;
                left: 0.3125rem;
                border-radius: 50%;
                width: 0.5rem;
                height: 0.5rem;
                font-size: 25px;
                line-height: 1.25rem;
                text-align: center;
                color: $color_6;
                background-color: $background-color_4;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                box-shadow: 0 0 1px #6e6e6e;
            }
        }
    }

    position: absolute;
    padding: 0;
    bottom: 0;
    list-style: none;
    display: block;
    text-align: center;
    margin: 0;
    width: 100%;

    li.slick-active {
        button {
            &::before {
                color: $color_4;
                background-color: $background-color_5;
            }
        }
    }
}

input[type="button"] {
    display: inline-block;
    cursor: pointer;
    line-height: 1em;
    padding: 0.6563rem 8%;
    min-height: 2.5rem;
    font-family: $font-family_4;
    font-size: 16px;
    font-size: 1rem;
    transition: all 0.3s ease;
    box-sizing: border-box;
    border-radius: 4px;
    letter-spacing: 0.2px;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    border: 2px solid #d71921;
    background-color: $background-color_3;
    color: $color_1;
}

input[type="file"] {
    border-radius: 0;
}

.slick-list {
    display: block;
    position: relative;
    overflow: hidden;
    padding: 0;
    margin: 0;
}

.slick-slider {
    display: block;
    position: relative;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;

    .slick-list {
        transform: translateZ(0);
    }

    .slick-track {
        transform: translateZ(0);
    }
}

ul.slick-slider {
    padding-left: 0;
}

#wrapper {
    position: relative;
    left: 0;
    transition: left 0.2s ease-in;
    width: 100%;
    border: 0;
    margin: auto;
    background-color: $background-color_1;
}

.slick-loading {
    .slick-slide {
        visibility: hidden;
    }

    .slick-track {
        visibility: hidden;
    }

    .slick-list {
        background: #fff url("../lib/slick/ajax-loader.gif") 50% no-repeat;
    }
}

.slick-initialized {
    .slick-slide {
        display: block;
    }
}

.cross-sell {
    ul {
        li.grid-tile {
            display: inline-block;
            height: 452px !important;
            margin-left: 0.4378rem !important;
        }
    }
}

.slick-next {
    position: absolute;
    height: 2rem;
    width: 1.5rem;
    min-height: 0;
    font-weight: 400;
    border-radius: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
    opacity: 0.75;
    z-index: 1;
    right: 0.0625rem;
    border: 0;
    background: 0 0;
    display: block;
    outline: 0;
    line-height: 0;
    font-size: 0;
    color: $color_5;
    cursor: pointer;

    &::before {
        content: "";
        display: inline-block;
        border-color: $border-color_1;
        border-style: solid;
        border-width: 1px 1px 0 0;
        width: 0.625rem;
        height: 0.625rem;
        transform: rotate(45deg);
    }
}

.slick-prev {
    &::before {
        content: "";
        display: inline-block;
        border-color: $border-color_1;
        border-style: solid;
        border-width: 1px 1px 0 0;
        width: 0.625rem;
        height: 0.625rem;
        transform: rotate(45deg);
        transform: rotate(-135deg);
    }

    border: 0;
    background: 0 0;
    display: block;
    outline: 0;
    line-height: 0;
    font-size: 0;
    color: $color_5;
    cursor: pointer;
    position: absolute;
    padding: 0;
    height: 2rem;
    width: 1.5rem;
    min-height: 0;
    font-weight: 400;
    border-radius: 0;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0.75;
    z-index: 1;
    left: 0.0625rem;
}

.slick-next.slick-disabled {
    &::before {
        opacity: 0.25;
    }
}

.slick-prev.slick-disabled {
    &::before {
        opacity: 0.25;
    }
}

.slick-dotted.slick-slider {
    margin-bottom: 1.875rem;
}

html.menu-active {
    overflow-x: hidden;
}

.p {
    line-height: 1.43;

    a {
        overflow: hidden;
        color: $color_2;
        text-decoration: underline;
        line-height: inherit;
    }
}

p {
    line-height: 1.43;

    a {
        overflow: hidden;
        color: $color_2;
        text-decoration: underline;
        line-height: inherit;
    }
}

ol {
    line-height: 2;
    letter-spacing: 0.2px;
    padding-left: 0.9375rem;

    li {
        padding-left: 0.9375rem;
    }
}

ul {
    line-height: 2;
    letter-spacing: 0.2px;
    padding-left: 0.9375rem;

    li {
        padding-left: 0.9375rem;
    }
}

.button {
    line-height: 1em;
    padding: 0.6563rem 8%;
    min-height: 2.5rem;
    font-size: 16px;
    font-size: 1rem;
    display: inline-block;
    transition: all 0.3s ease;
    box-sizing: border-box;
    border-radius: 4px;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    cursor: pointer;
    border: 2px solid #d71921;
    background-color: $background-color_3;
    color: $color_1;
    font-family: $font-family_4;
    letter-spacing: 0.2px;
}

.buttonstyle {
    line-height: 1em;
    padding: 0.6563rem 8%;
    min-height: 2.5rem;
    font-size: 16px;
    font-size: 1rem;
    display: inline-block;
    transition: all 0.3s ease;
    box-sizing: border-box;
    border-radius: 4px;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    cursor: pointer;
    border: 2px solid #d71921;
    background-color: $background-color_3;
    color: $color_1;
    font-family: $font-family_4;
    letter-spacing: 0.2px;
}

.menu-category {
    a {
        font-size: 15px;
        font-size: 0.9375rem;
        display: block;
        color: $color_2;
        font-family: $font-family_3;
        font-weight: 400;
        text-decoration: none;
    }

    margin: 0;
    padding: 0;
    -webkit-font-smoothing: subpixel-antialiased;

    li {
        list-style: none;
        padding-left: 0;
    }

    .arrow {
        position: absolute;
        top: 25px;
        right: 0;
    }
}

.mobile-menu-utility-user {
    a {
        font-size: 15px;
        font-size: 0.9375rem;
        display: block;
        color: $color_2;
        font-family: $font-family_3;
        font-weight: 400;
        text-decoration: none;
    }

    margin: 0;
    padding: 0;
    -webkit-font-smoothing: subpixel-antialiased;
    display: block;
    list-style: none;

    li {
        list-style: none;
        padding-left: 0;
    }

    .arrow {
        position: absolute;
        top: 25px;
        right: 0;
    }
}

label {
    font-size: 15px;
    font-size: 0.9375rem;
    display: block;
    color: $color_2;
    font-family: $font-family_4;
    letter-spacing: 0.2px;
    margin-bottom: 0.4375rem;
}

.form-row {
    margin: 0 0 1.25rem;

    &::after {
        content: "";
        display: table;
        clear: both;
    }
}

.top-banner {
    &::after {
        content: "";
        display: table;
        clear: both;
    }

    position: relative;
    padding: 1.25rem 0.625rem 0.625rem;

    * {
        box-sizing: border-box;
    }
}

nav[role="navigation"] {
    &::after {
        content: "";
        display: table;
        clear: both;
    }

    position: absolute;
    top: 0;
    left: -90%;
    width: 90%;
    margin-top: 0;
    background-color: $background-color_1;
    z-index: 2;
}

.char-count {
    line-height: 2em;
    font-size: 12px;
    font-size: 0.75rem;
    color: $color_2;
}

.form-caption {
    line-height: 2em;
    font-size: 12px;
    font-size: 0.75rem;
    color: $color_2;
    clear: left;
    width: auto;
}

#main {
    box-sizing: border-box;
    margin: auto;
    width: calc(100% - 30px);
    max-width: 1280px;
    padding: 1.5625rem 0;

    .home-bottom-first {
        background: #fff;
    }

    .home-bottom-second {
        background: #fff;
    }

    .home-bottom-third {
        background: #fff;
    }
}

.account-menu-toggle {
    display: block;
    float: left;
    min-height: 0;
    line-height: normal;
    padding: 0;
    border: 0;
    border-radius: 0;
    margin: 0 3% 0 0.625rem;
    font-size: 1.1em;
    background-color: $background-color_6;
    color: $color_2;
}

.menu-toggle {
    display: block;
    float: left;
    min-height: 0;
    line-height: normal;
    padding: 0;
    border: 0;
    border-radius: 0;
    margin: 0 3% 0 0.625rem;
    font-size: 1.1em;
    background-color: $background-color_6;
    color: $color_2;
}

.mobile-cap {
    box-sizing: border-box;
    padding: 15px;
    position: relative;
    text-align: center;
}

.mobile-menu-level-label {
    display: none;
    font-family: $font-family_4;
    font-size: 16px;
    font-size: 1rem;
    padding: 12px 20px;
    box-sizing: border-box;
    background-color: $background-color_4;
    line-height: 1rem;
}

.hp-xUp {
    picture {
        display: block;
    }

    text-align: center;
    padding: 20px 0;

    .cta {
        display: block;
        margin: 5px auto;
    }

    .upCell {
        position: relative;
    }

    a {
        font-family: $font-family_5;
        font-weight: 700;
        color: $color_11;
        font-size: 18px;
        letter-spacing: 0.2px;
    }

    img {
        display: block;
        width: 100%;
    }

    .upRowContainer {
        width: 100%;
        margin: 0 auto;
        max-width: 1210px;
        display: flexbox;
        display: flex;
        flex-wrap: row wrap;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}

.level-1 {
    >li {
        a {
            display: block;

            .icon {
                margin-right: 6px;
            }
        }

        background-color: $background-color_6;

        &:first-child {
            border: 0;
        }

        >a {
            color: $color_2;
        }
    }
}

.mobile-menu-level-label.show-menu-item {
    display: block;
}

.menu-utility-user {
    li {
        &:first-child {
            border: 0;
        }

        list-style: none;
        padding: 0.8em 1rem;
        border-top: 1px solid #393939;

        a {
            text-decoration: none;
            color: $color_1;
        }
    }

    display: none;
    margin: 0;
    padding: 0;

    .user-info {
        .user-panel {
            transition: all 0.3s ease;
            width: 290px;
            box-sizing: border-box;
            text-align: left;
            top: 40px;
            position: absolute;
            opacity: 0;
            visibility: hidden;
            right: -1.5rem;
            z-index: 20;
            margin-top: 0;
            background-color: $background-color_1;
            box-shadow: 0 2px 9px 0 silver;

            h3 {
                padding: 0;
                margin: 0.625rem 0 1.125rem;
                text-transform: capitalize;
            }

            h6 {
                padding: 0;
                margin: 0.625rem 0 1.125rem;
                text-transform: capitalize;
                line-height: 1rem;
            }

            .user-panel-wrapper {
                position: relative;
                padding: 1.25rem;
                box-sizing: border-box;

                &::after {
                    content: "";
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    background: #fff;
                    transform: rotate(45deg);
                    top: -8px;
                    right: 24px;
                    box-shadow: -4px -4px 20px -5px rgb(0 0 0 / 25%);
                    z-index: 100;
                }

                &::before {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 40px;
                    top: -20px;
                    background: 0 0;
                }
            }

            .user-messages {
                .p {
                    margin-bottom: 1.125rem;
                    margin-top: 0;

                    a {
                        line-height: 1rem;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                p {
                    margin-bottom: 1.125rem;
                    margin-top: 0;

                    a {
                        line-height: 1rem;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .user-links {
                a {
                    &:last-child {
                        margin-bottom: 0;
                    }

                    line-height: 1em;
                    padding: 0.6563rem 8%;
                    min-height: 2.5rem;
                    display: inline-block;
                    transition: all 0.3s ease;
                    box-sizing: border-box;
                    border-radius: 4px;
                    letter-spacing: 0.2px;
                    text-align: center;
                    text-decoration: none;
                    text-transform: none;
                    cursor: pointer;
                    border: 2px solid #d71921;
                    background-color: $background-color_3;
                    color: $color_1;
                    margin-bottom: 0.625rem;
                    width: 100%;
                    font-family: $font-family_4;
                    font-size: 16px;
                    font-size: 1rem;
                }

                padding-left: 0;
                margin-top: 0.625rem;
                padding-top: 0.625rem;
                border-top: 1px solid silver;
            }

            a {
                display: block;
                padding: 0.5rem 0;
                color: $color_2;
            }

            .user-logout {
                text-decoration: underline;
            }
        }
    }
}

#mini-cart {
    .mini-cart-name {
        a {
            color: $color_2;
        }
    }

    position: absolute;
    top: 21px;
    right: 20px;
    z-index: 20;
}

.mini-cart-total {
    .mini-cart-link {
        color: $color_2;
    }

    .mini-cart-empty {
        transition: color 0.5s ease;
        color: $color_2;
    }
}

.mini-cart-content {
    display: none;
}

.mini-cart-content-modal {
    display: none;
}

.skip-nav {
    position: absolute;
    left: -10000px;
}

.primary-logo {
    z-index: 1;
    width: 146px;
    height: 18px;
    background: url("../images/svg-icons/logo-mobile.svg") no-repeat;
    background-size: contain;
    margin: 0 auto;
    position: relative;
    top: 3px;

    a {
        display: block;
        height: 100%;
    }
}

.header-search {
    clear: both;
    position: relative;
    z-index: 1;
    width: 100%;
    height: 40px;
    border: 1px solid silver;
    border-radius: 3px;
    margin: 1.125rem auto 0;
    background-color: $background-color_1;
    color: $color_4;

    input[type="text"] {
        float: left;
        width: calc(100% - 2.5rem);
        height: 40px;
        padding-left: 16px;
        margin: 0;
        background-color: $background-color_6;
        border: 0;
    }

    button {
        padding: 0.25rem 0.6rem;
        border: 0;
        background-color: $background-color_6;
        color: $color_2;
        position: absolute;
        right: 0;
        top: 0;
    }
}

.h2 {
    font-family: $font-family_4;
    font-size: 24px;
    font-size: 1.5rem;
}

.h3 {
    font-family: $font-family_4;
    font-size: 24px;
    font-size: 1.5rem;
    font-size: 20px;
    font-size: 1.25rem;
}

h2 {
    font-family: $font-family_4;
    font-size: 24px;
    font-size: 1.5rem;
}

h3 {
    font-family: $font-family_4;
    font-size: 24px;
    font-size: 1.5rem;
    font-size: 20px;
    font-size: 1.25rem;
}

.h4 {
    font-size: 18px;
    font-size: 1.125rem;
    font-family: $font-family_4;
}

h4 {
    font-size: 18px;
    font-size: 1.125rem;
    font-family: $font-family_4;
}

.footer-container {
    .footer-item {
        h3 {
            font-family: $font-family_4;
            font-size: 15px;
            font-size: 0.9375rem;
            letter-spacing: 0.2px;
            position: relative;
            padding: 0 0.625rem;
            margin: 0;
            line-height: 46px;
            line-height: 2.875rem;
        }

        display: block;
        width: 100%;
        border-bottom: 1px solid silver;
        box-sizing: border-box;

        h3.toggle {
            &::after {
                content: "";
                display: block;
                position: absolute;
                right: 1rem;
                top: 0.9375rem;
                width: 1rem;
                height: 1rem;
                background: url("../images/svg-icons/increase.svg") no-repeat 50%;
            }
        }

        h4 {
            margin: 0;
        }

        .note {
            margin-bottom: 0.625rem;
        }
    }

    color: $color_2;
    box-sizing: border-box;
    margin: auto;
    width: calc(100% - 30px);
    max-width: 1280px;

    .footer-item.email-item {
        padding: 0.75rem 0;

        h4 {
            line-height: 26px;
            line-height: 1.625rem;
        }
    }

    .footer-item.social-item {
        border: 0;
        margin-bottom: 1.25rem;
        padding: 0 0.625rem;

        a {
            line-height: 19px;
            line-height: 1.1875rem;
        }

        .call-us {
            line-height: 24px;
            line-height: 1.5rem;
            margin-top: 1.25rem;

            a {
                font-family: $font-family_4;
                font-size: 16px;
                font-size: 1rem;
            }
        }
    }

    .menu-footer {
        li {
            line-height: 19px;
            line-height: 1.1875rem;
            float: none;
            list-style: none;
            margin-bottom: 0.8125rem;
            padding-left: 0.625rem;
        }

        float: none;
    }

    a {
        color: $color_2;
        text-decoration: none;
    }
}

.h5 {
    font-family: $font-family_4;
}

.h6 {
    font-family: $font-family_4;
    font-size: 15px;
    font-size: 0.9375rem;
    letter-spacing: 0.2px;
}

h5 {
    font-family: $font-family_4;
}

h6 {
    font-family: $font-family_4;
    font-size: 15px;
    font-size: 0.9375rem;
    letter-spacing: 0.2px;
}

.product-tile {
    .product-swatches {
        li.last {
            margin: 0;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            overflow: hidden;
        }

        li {
            float: left;
            line-height: 1;
            padding: 0;
            margin: 0 0.625rem 0.625rem 0;
            border: 1px solid #000;
        }

        .swatch {
            display: block;
            width: 1.25rem;
            height: 1.25rem;
            position: relative;

            img {
                height: 100%;
                width: 100%;
            }
        }

        .product-swatches-all {
            float: left;
            line-height: 20px;
            line-height: 1.25rem;
            margin-left: 0.625rem;
        }
    }

    display: flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    position: relative;

    &::after {
        content: "";
        display: table;
        clear: both;
    }

    img {
        max-width: 100%;
    }

    .product-image {
        -ms-flex-negative: 0;
        flex-shrink: 0;
        height: auto;
        position: relative;
        margin-bottom: 0.625rem;
        min-height: 136px;

        .thumb-link {
            display: block;
            position: relative;
            width: 100%;
            max-width: 100%;

            img {
                vertical-align: top;
            }
        }
    }

    .product-name {
        -webkit-box-flex: 1;
        flex: 1 0 auto;
        display: flexbox;
        display: flex;
        letter-spacing: 0.1px;
        line-height: 1.43;
    }

    .product-pricing {
        font-family: $font-family_4;
        font-size: 16px;
        font-size: 1rem;
        margin: 0.4375rem 0 0;
        line-height: 22px;
        line-height: 1.375rem;
        display: flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;

        .product-standard-price {
            font-family: $font-family_3;
            font-weight: 400;
            text-decoration: line-through;
        }

        .product-standard-price.basePrice {
            font-family: $font-family_4;
            font-size: 16px;
            font-size: 1rem;
            text-decoration: none;
        }

        .product-sales-price {
            color: $color_7;
        }

        .product-standard-price + .product-sales-price {
            color: $color_7;
        }

        .product-sales-price.list-price {
            color: $color_9;
        }

        .price-standard-range {
            text-decoration: line-through;
            font-family: $font-family_3;
            font-weight: 400;
        }
    }

    .product-badge {
        position: absolute;
        top: 0;
        left: -0.3125rem;
    }

    .rating-wrapper {
        height: 16px;
        margin-top: 0.75rem;
    }
}

.social-links {
    margin: 1.25rem 0;
    padding: 0;
    display: table;
    width: 100%;
    line-height: 1;

    li {
        display: table-cell;
        padding: 0 0.625rem;
        text-align: center;
        list-style: none;

        a {
            text-decoration: none;
        }

        .icon {
            vertical-align: top;
        }
    }

    li.first {
        text-align: left;
        padding-left: 0;
    }

    li.last {
        text-align: right;
        padding-right: 0;
    }
}

.copyright {
    text-align: center;
    background: #ebebeb;
    color: $color_2;
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 2.3333em;

    .copyright-container {
        .copy-links {
            a {
                margin: 0 0.625rem;
                color: $color_2;
                text-decoration: none;
            }
        }
    }
}

.back-to-top {
    visibility: hidden;
    position: fixed;
    bottom: 30px;
    right: 30px;
    opacity: 0;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s;

    .back-to-top-icon {
        display: inline-block;
        width: 36px;
        height: 36px;
    }
}

.ui-helper-hidden-accessible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

[class^="ui-"] {
    box-sizing: content-box;

    &::after {
        box-sizing: content-box;
    }

    &::before {
        box-sizing: content-box;
    }
}

.horizontal-carousel-with-arrows {
    .slick-slide {
        box-sizing: border-box;
        width: 94%;
        padding: 1.25rem 1.25rem 1.875rem;
        margin: 0 0.5rem 1.25rem;
        vertical-align: top;
        background: #fff;
        box-shadow: 0 2px 9px 0 #a5a5a5;
    }

    .tiles-container {
        &:not(.slick-initialized) {
            display: none;
        }
    }

    .slick-dots {
        position: relative;

        li {
            button {
                height: 0.75rem;
                width: 0.75rem;

                &::before {
                    opacity: 1;
                    box-shadow: none;
                    height: 0.75rem;
                    width: 0.75rem;
                }
            }
        }

        li.slick-active {
            button {
                &::before {
                    color: $color_7;
                    background-color: $background-color_3;
                    box-shadow: none;
                    opacity: 1;
                }
            }
        }
    }

    .slick-track {
        display: flexbox;
        display: flex;

        .slick-slide {
            display: flexbox;
            display: flex;
            height: auto;

            img {
                width: 100%;
            }
        }
    }
}

.horizontal-carousel {
    .slick-dots {
        li {
            button {
                height: 0.75rem;
                width: 0.75rem;

                &::before {
                    opacity: 1;
                    box-shadow: none;
                    height: 0.75rem;
                    width: 0.75rem;
                }
            }
        }

        li.slick-active {
            button {
                &::before {
                    color: $color_7;
                    background-color: $background-color_3;
                    box-shadow: none;
                    opacity: 1;
                }
            }
        }

        position: relative;
    }

    .slick-slide {
        box-sizing: border-box;
        width: 94%;
        padding: 1.25rem 1.25rem 1.875rem;
        margin: 0 0.5rem 1.25rem;
        vertical-align: top;
        background: #fff;
        box-shadow: 0 2px 9px 0 #a5a5a5;
    }

    .tiles-container {
        &:not(.slick-initialized) {
            display: none;
        }
    }

    .slick-track {
        display: flexbox;
        display: flex;

        .slick-slide {
            display: flexbox;
            display: flex;
            height: auto;

            img {
                width: 100%;
            }
        }
    }
}

.pt_storefront {
    .horizontal-carousel {
        .slick-dots {
            button {
                &::before {
                    color: $color_8;
                    background-color: $background-color_2;
                }
            }
        }
    }

    #main {
        width: 100%;
        max-width: 100%;
        padding: 0 0 3.75rem;
    }
}

#ModalViewDialog {
    color: $color_2;
}

.rating {
    color: $color_2;
}

.top-categories-slot {
    .category-tile {
        img {
            max-width: 100%;
        }

        margin-right: 5%;
        margin-left: 5%;
        margin-top: 4%;
        width: 40%;
        display: inline-block;

        &:nth-child(-n+2) {
            margin-top: 0;
        }
    }

    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    text-align: center;
    padding-top: 1.875rem;
    box-sizing: border-box;
    background: #fff;

    .top-categories-wrapper {
        display: flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 2.5rem;
        padding: 0 2%;
    }
}

.grid-tile {
    .product-tile {
        height: 100%;
        box-sizing: border-box;
        padding: 1.25rem 1.25rem 0;
    }
}

.horizontalcarousel-with-banner {
    margin-top: 2.5rem;

    .carousel-wrapper {
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
        box-sizing: border-box;
        padding: 0 1.25rem;
    }
}

.mobile-category-buttons {
    text-align: center;
    box-sizing: border-box;
    padding: 0 1.25rem;
    margin-top: 1.875rem;
}

.home-bottom-first {
    .recommendations-container {
        position: relative;
        margin-left: 0.5%;
        margin-right: 1%;
    }

    .recommendations {
        padding: 1.25rem 0 0.9375rem;
        position: relative;
        width: calc(100% + 0.125rem);

        .slick-prev {
            left: 0.19rem;
        }

        .slick-next {
            right: 0.25rem;
        }

        .grid-tile {
            background: #fff;
            box-shadow: 0 0 9px 0 silver;
            width: 18.75rem;
            height: auto;
            padding: 0;
            margin: 0.3125rem 0.4375rem;

            .product-tile {
                margin-left: auto !important;
            }
        }
    }
}

.home-bottom-second {
    background: #f3f3f3;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(25%, #f3f3f3), color-stop(58%, #fff));
    background: linear-gradient(180deg, #f3f3f3 25%, #fff 58%);
    filter: progid:dximagetransform.microsoft.gradient(startColorstr="#f3f3f3", endColorstr="#ffffff", GradientType=0);
    background-position-y: 60px;
}

a.show-menu-item[data-wa-event-action="sale"] {
    color: $color_10 !important;

    &:hover {
        color: $color_10 !important;
    }
}

a[data-wa-event-label="clearance"] {
    color: $color_10 !important;
}

.openModal {
    cursor: pointer;
}

.hp-threeUp {
    .upCell {
        width: 100%;
        margin: 15px 0;
        display: inline-block;
        max-width: 600px;
    }

    .upRowContainer {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

.brand-carousel {
    margin: 15px auto 52px;

    .slick-slide {
        background-color: $background-color_7;
        padding: 10px 15px 30px;
        box-shadow: none;
    }

    .product-tile {
        text-align: center;
        margin: 0 auto;

        .logo {
            width: auto;
            margin: 0 auto;
            min-height: 80px;
        }

        .review-product-name {
            font-family: $font-family_6;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: $color_2;
            max-width: 180px;
            margin: 15px auto 0;
        }

        .rating {
            margin: 15px 0 0;
        }

        .star-rating {
            display: inline;
            height: 18px;
        }

        .review-headline {
            font-family: $font-family_7;
            font-size: 24px;
            line-height: 26px;
            color: $color_2;
            font-weight: 800;
            max-width: 220px;
            margin: 15px auto 0;
        }

        .review-copy {
            font-family: $font-family_6;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: $color_2;
            max-width: 224px;
            margin: 15px auto 0;
        }

        .reviwer-name {
            font-family: $font-family_6;
            font-weight: 600;
            font-size: 13px;
            color: $color_2;
            max-width: 200px;
            text-transform: uppercase;
            margin: 15px auto 0;
        }
    }

    .slick-track {
        .slick-slide {
            img {
                width: auto;
            }
        }
    }
}

.recommendations {
    padding: 1.25rem 0 0.9375rem;
    position: relative;
    width: calc(100% + 0.125rem);

    .slick-prev {
        left: 0.19rem;
    }

    .slick-next {
        right: 0.25rem;
    }

    .slick-list {
        width: 280px;
        margin: 0 auto;
    }

    .product-tile {
        .product-badge {
            left: 0;
        }

        .product-image {
            margin: 0 auto 0.625rem;
        }

        .product-name {
            margin: 0 auto;
            width: 92%;
            max-width: 260px;
        }

        .product-pricing {
            margin: 0.4375rem auto 0;
            width: 260px;
            text-align: left;
        }
    }

    .product-swatches {
        margin: 0 auto;
        max-width: 260px;
        text-align: left;
    }

    .grid-tile {
        background: #fff;
        box-shadow: 0 0 9px 0 silver;
        width: 18.75rem;
        height: auto;
        padding: 0;
        margin: 0.3125rem 0.4375rem;
    }
}

.recommendations-container {
    margin-left: 0.5%;
    margin-right: 1%;
}

.br-l {
    display: none;
}

.br-m {
    display: none;
}

.br-xl {
    display: none;
}

.br-s {
    display: block;
}
